<template>
  <BackofficeBase>
    <body class="dash__back">
      <slot></slot>
      <div class="overlay"></div>
      <div class="dashboard__wrapper">
        <div class="dashboard__main">
          <div class="worker__wrapper">
            <div class="head__">
              <h6>ניהול עובדים</h6>
            </div>
			<div class="filter__">
    <form action="">
      <div class="search">
        <div class="group__input">
          <input
            type="text"
            placeholder="חיפוש עובד"
            v-model="searchQuery"
            @input="searchEmployee"
          />
          <button>
            <img src="../../assets/img/search.svg" alt="search" />
          </button>
        </div>
      </div>

                <div class="buttons" @click="togglePopup">
                  <a href="#" class="export-btn"
                    ><span
                      ><svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19 3.5H5C3.9 3.5 3 4.4 3 5.5V19.5C3 20.6 3.9 21.5 5 21.5H19C20.1 21.5 21 20.6 21 19.5V5.5C21 4.4 20.1 3.5 19 3.5ZM9.5 12C9.5 12.8 8.8 13.5 8 13.5H7V15.5H5.5V9.5H8C8.8 9.5 9.5 10.2 9.5 11V12ZM14.5 14C14.5 14.8 13.8 15.5 13 15.5H10.5V9.5H13C13.8 9.5 14.5 10.2 14.5 11V14ZM18.5 11H17V12H18.5V13.5H17V15.5H15.5V9.5H18.5V11ZM12 11H13V14H12V11ZM7 11H8V12H7V11Z"
                          fill="#161616"
                        />
                      </svg> </span
                    >הנפקת דוח של כל העובדים</a
                  >
                </div>
                <div class="buttons">
                  <router-link
                    :to="{
                      path: '/backoffice/new_emploeey',
                      query: { id: '0' },
                    }"
                    class="regular-btn"
                    >הוספה של עובד חדש</router-link
                  >
                </div>
              </form>
            </div>
            <div class="table__wrapper">
              <table class="regular-table">
                <tr>
                  <th>שם העובד</th>
                  <th>מספר טלפון</th>
                  <th>פעולות</th>
                </tr>
				<tr
        v-for="employees in filteredEmployees"
        :key="employees.id"
        v-scroll-to="employees.highlight ? `employee-${employees.id}` : null"
        :id="`employee-${employees.id}`"
        :style="{ backgroundColor: employees.highlight ? 'yellow' : 'white' }"
      >
	<!-- </tr> -->
                <!-- <tr v-for="employees in data" :key="employees.id"> -->
                  <td>
                    <p class="name">
                      {{ employees.first_name }} {{ employees.last_name }}
                    </p>
                  </td>
                  <td>
                    <p class="number">{{ employees.phone }}</p>
                  </td>
                  <td>
                    <div class="buttons">
                      <router-link v-if="employees.is_active==true"
                        :to="{
                          path: '/backoffice/user_report',
                          query: { id: employees.id },
                        }"
                        :class="{ done: employees.has_report_yesterday }"
                        >דיווח</router-link
                      >
                      <router-link 
                        :to="{
                          path: '/backoffice/user_monthly_report',
                          query: { id: employees.id },
                        }"
                        >דוחות</router-link
                      >
                      <router-link
                        :to="{
                          path: '/backoffice/emploeey_loans',
                          query: { id: employees.id },
                        }"
                        >הלוואות</router-link
                      >
                      <!-- <a href="#">דוחות</a> -->
                      <!-- <a href="#">הלוואות</a> -->
                      <router-link
                        :to="{
                          path: '/backoffice/emploeey',
                          query: { id: employees.id },
                        }"
                        >עריכה</router-link
                      >
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <BackofficeSidebar></BackofficeSidebar>
      </div>

      <div class="modal__wrapper" style="display: none" v-show="showPopup">
        <div class="inner__modal">
          <div class="modal__box export">
            <a href="#"
              ><img
                src="../../assets/img/close.svg"
                alt="close"
                @click="togglePopup"
            /></a>

          <form action="">
            <h2>הנפקת דו”ח</h2>
            <div class="group__input">
              <p>בחירת חודש</p>
              <input type="month" v-model="maxDate" @change="handleMonthChange" :max="maxDate" />
            </div>
            <div class="controls">
              <button type="button" class="regular-btn" @click="loadAllEmployeesData">
                הנפק דו”ח
              </button>
              <a href="#" class="outline-btn cancel__button" @click="togglePopup">ביטול</a>
            </div>
           </form>
            <div ref="pdfContent" style="display: none">
              <table style="border-collapse: collapse; width: 100%; margin-top: 5%;  margin-left: 15%; margin-right: 5%; margin-bottom: 5%" >
                <thead>
                  <tr style="border-bottom: 1px solid black">
                    <th style="border: 1px solid black; padding: 8px">
						שם 
                    </th>
					<th style="border: 1px solid black; padding: 8px">
                      משפחה
                    </th>
                    <th style="border: 1px solid black; padding: 8px">
                       טלפון
                    </th>
                    <th style="border: 1px solid black; padding: 18px">סטטוס
					</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="employee in data"
                    :key="employee.id"
                    style="border-bottom: 1px solid black"
                  >
                    <td style="border: 1px solid black; padding: 8px">
                      {{ employee.first_name }}
                    </td>
                    <td style="border: 1px solid black; padding: 8px">
                      {{ employee.last_name }}
                    </td>
                    <td style="border: 1px solid black; padding: 8px">
                      {{ employee.phone }}
                    </td>
                    <td style="border: 1px solid black; padding: 8px">
                      {{ employee.is_active ? "עובד" : "לא-עובד" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </body>
  </BackofficeBase>
</template>

<script>
//   import BackofficeBase from "./BackofficeBase";
import BackofficeSidebar from "./BackofficeSidebar";
import BackendModel from "../../models/BackendModel";
import BackofficeBase from "./BackofficeBase";
// import jsPDF from 'jspdf';
import "jspdf-autotable";
import html2pdf from "html2pdf.js";

export default {
  name: "BackofficeDashbord",
  components: {
    BackofficeBase,
    BackofficeSidebar,
  },
  data() {
    return {
      showPopup: false, // משתנה המציין אם ה-popup יוצג או לא
      data: [],
      searchQuery: '',
      filteredEmployees: [],
      month:'',
      year:'',
      allEmployeesData:{},
      maxDate: this.getCurrentMonth(),
    };
  },
  async mounted() {
    let backendModel = new BackendModel();
    let data = await backendModel.backendRequest(
      "/Api/service/backoffice/get_employees",
      {}
    );
    this.data = data.data.data.employees;
	this.filteredEmployees = this.data;
    console.log(this.data);
  },
  methods: {
    getCurrentMonth() {
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // January is 0!
      if (mm < 10) {
        mm = `0${mm}`; // Add leading 0 if needed
      }
      return `${yyyy}-${mm}`;
    },
    handleMonthChange(event) {
      const selectedDate = new Date(event.target.value);
      this.month = selectedDate.getMonth() + 1; // JavaScript מחשב את החודשים מ-0 ל-11
      this.year = selectedDate.getFullYear();
    },
    togglePopup() {
      this.showPopup = !this.showPopup; // משנה את ערך המשתנה בכל לחיצה על הכפתור
    },
  
	searchEmployee() {
      this.filteredEmployees = this.data.filter(employee => {
        const fullName = `${employee.first_name} ${employee.last_name}`.toLowerCase();
        const matchesSearch = fullName.includes(this.searchQuery.toLowerCase());

        // Highlight the employee if their name matches the search query
        employee.highlight = matchesSearch;

        return matchesSearch;
      });
    },


    async loadAllEmployeesData() {
    let backendModel = new BackendModel();
    if (!this.month || !this.year) {
    const today = new Date();
    this.month = today.getMonth() + 1;
    this.year = today.getFullYear();
  }
    this.allEmployeesData = await backendModel.backendRequest("/Api/service/backoffice/get_all_users_monthly_reports", {
      month: this.month,
      year: this.year,
    });
    console.log(this.allEmployeesData.data.arr[0])
    if (this.allEmployeesData.data.res === "OK" && this.allEmployeesData.data.arr.length > 0) {
      this.allEmployeesData.data.arr.forEach((employeeData) => {
        this.createEmployeePDF(employeeData);
      });
    }
  },

  // createEmployeePDF
  createEmployeePDF(employeeData) {
  const container = document.createElement("div");
  container.classList.add("pdf-container");

  // Add employee name to the top
  const employeeName = document.createElement("h3");
  employeeName.textContent = `${employeeData.first_name} - ${employeeData.last_name}`;
  container.appendChild(employeeName);

  // Prepare table data
  const tableData = employeeData.table;

  // Add table
  const table = document.createElement("table");
  table.classList.add("pdf-table");
  const headers = ["working_date_to_show", "day_to_show", "detail_type_he", "amount", "rate", "payment"];

  // Add headers
  const headerRow = document.createElement("tr");
  headers.forEach((header) => {
    const th = document.createElement("th");
    // Customize titles here
    if (header === "working_date_to_show") th.textContent = "תאריך";
    else if (header === "day_to_show") th.textContent = "יום";
    else if (header === "detail_type_he") th.textContent = "פירוט";
    else if (header === "amount") th.textContent = "כמות";
    else if (header === "rate") th.textContent = "מחיר";
    else if (header === "payment") th.textContent = "תשלום";
    headerRow.appendChild(th);
  });
  table.appendChild(headerRow);

  // Add table rows
  tableData.forEach((rowData) => {
    const row = document.createElement("tr");
    if (rowData["detail_type_he"] === "סיכום יומי") {
      row.style.backgroundColor = "#d3d3d3"; // Highlight daily summary rows
    }
    headers.forEach((header) => {
      const td = document.createElement("td");
      td.textContent = rowData[header];
      row.appendChild(td);
    });
    table.appendChild(row);
  });

  container.appendChild(table);
  let totalAmountForMinimumDailyHours = employeeData.table
    .filter(row => row.detail_type === "minimum_daily_hours")
    .reduce((sum, row) => sum + parseFloat(row.amount), 0)
    .toFixed(2);

  // Calculate pay per hour if needed
  let pay_to_hour = totalAmountForMinimumDailyHours > 0 
    ? (employeeData.total_payment_for_minimum_daily_hours / totalAmountForMinimumDailyHours).toFixed(2) 
    : 0;
    let dailyTravelExpense = (
        employeeData.travel_expenses / employeeData.travel_expenses_days
      ).toFixed(2);
  // Add data items
  const dataItems = [
  {
      label: "תשלום שעתי",
      value: `${pay_to_hour}x${totalAmountForMinimumDailyHours}=${employeeData.total_payment_for_minimum_daily_hours.toFixed(2)}`
    },
    {
          label:
            "תשלום עבור ימי נסיעות  - " +
            dailyTravelExpense +
            "x" +
            employeeData.travel_expenses_days +
            "=" +
            employeeData.travel_expenses,
    },
    { label: "סך הכל פרמיה  -", value: employeeData.total_premium.toFixed(2) },
    { label: "ימי עבודה", value: employeeData.number_of_working_days },
    { label: "תשלום על ימי נסיעות", value: employeeData.travel_expenses },
    { label: "מספר ימי נסיעות", value: employeeData.travel_expenses_days },
    { label: "סך הכל תקבולים", value: employeeData.total_payment },
    { label: "מפרעות", value: employeeData.prepay_type },
    { label: "הלוואות", value: employeeData.loan_type },
    { label: "סך הכל לתשלום", value: employeeData.amount_to_pay }
    // Add more data items as needed
  ];


  const dataContainer = document.createElement("div");
  dataContainer.classList.add("pdf-data-container");

  dataItems.forEach((item) => {
    const dataItem = document.createElement("div");
    dataItem.classList.add("pdf-data-item");
    const label = document.createElement("span");
    label.classList.add("pdf-data-label");
    label.textContent = `${item.label}: `;
    const value = document.createElement("span");
    value.classList.add("pdf-data-value");
    value.textContent = item.value;
    dataItem.appendChild(label);
    dataItem.appendChild(value);
    dataContainer.appendChild(dataItem);
  });

  container.appendChild(dataContainer);

  // Convert container to PDF using html2pdf
  html2pdf()
    .set({
      margin: 10,
      filename: `${employeeData.first_name}_${employeeData.last_name}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, logging: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    })
    .from(container)
    .save();
}


  },
};
</script>
<style>
@import "../../assets/new_style.css";
.pdf-table {
  border-collapse: collapse;
  width: 100%;
}

.pdf-table th,
.pdf-table td {
  border: 1px solid black;
  padding: 8px;
}

.pdf-table th {
  background-color: #f2f2f2;
}

.pdf-data-item {
  margin-bottom: 10px;
}

.pdf-data-label {
  font-weight: bold;
}

.pdf-data-value {
  margin-left: 5px;
}
</style>
